
import { Options, Vue } from "vue-class-component";
import Loading from "../components/Loading.vue";
import CourseCard from "../components/CourseCard.vue";
import { CourseTaughtType, CourseType } from "../types/course/course-type";
import { courseListPendingHandler, courseListTaughtHandler } from "../handlers/course/course-get-auth-handlers";
import { TypeSubjectEnum } from "../types/enum/type-course";
import { splitToArrays } from "../helpers/split-to-arrays";
import { ParsedCourseType } from "../types/ui/parsed-courses";
import { isLoggedIn } from "../handlers/handlers-helpers";

@Options({
  components: { Loading, CourseCard },
})
export default class Teacher extends Vue {
  private course_taught_list: Array<CourseTaughtType> = [];
  private course_taught_list_parsed: Array<ParsedCourseType> = [];
  private type_taught: TypeSubjectEnum = TypeSubjectEnum.TAUGHT;

  private course_pending_list: Array<CourseType> = [];
  private type_pending: TypeSubjectEnum = TypeSubjectEnum.PENDING;

  mounted(): void {
    if (isLoggedIn()) {
      courseListTaughtHandler()
        .then((response_course_list_taught: Array<CourseTaughtType> | void) => {
          this.course_taught_list = response_course_list_taught || [];
          this.course_taught_list_parsed = splitToArrays(this.course_taught_list);
        })
        .catch((error) => {
          console.error(error);
        });

      courseListPendingHandler()
        .then((response_course_pending_list: Array<CourseType> | void) => {
          this.course_pending_list = response_course_pending_list || [];
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
