
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class Loading extends Vue {
  @Prop() readonly loading!: boolean;
}
