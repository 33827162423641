import { CourseType } from "@/types/course/course-type";
import { ParsedCourseType } from "@/types/ui/parsed-courses";

export function splitToArrays(received_list: Array<CourseType>): Array<ParsedCourseType> {
  if (!received_list) return [];
  const parsedGroups: Array<ParsedCourseType> = [];

  for (let i = 0; i < received_list.length; i++) {
    const course = received_list[i];
    const group = parsedGroups.filter((group) => {
      return group.year.start_year === course.year.start_year && group.year.end_year === course.year.end_year;
    });
    if (group.length > 0) {
      parsedGroups[parsedGroups.indexOf(group[0])].courses.push(course);
    } else {
      parsedGroups.push({
        year: course.year,
        courses: [course],
      });
    }
  }
  return parsedGroups;
}
